import React from 'react'
import ModalWithForm from '../Components/ModalWithForm';
import DemoModal from '../Components/DemoModal';
import Cardrs3 from '../Components/Cardrs3';

const Solutions = () => {
  return (
    <div className='solu-container'>
        <h2 className='hehe'>Solutions</h2>
        
        {/* <ModalWithForm/> */}
        <DemoModal/>
        <Cardrs3/>
        
    </div>
  )
}

export default Solutions;