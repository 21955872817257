// Cardrs.js

import React, { useEffect } from 'react';
import '../Css/Cardrs.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const Cardrs = () => {
  const cardrsItems = [
    {
      id: 1,
      title: 'Nidhi Company Software',
      content: 'Nidhi Company Software automates member management, deposits, loans, and compliance, ensuring efficient operations and regulatory adherence for Nidhi companies.',
      date: '2024-02-24',
      image: 'Nidhi.png',
      link: '',
    },

    {
      id: 2,
      title: 'Micro Finance Software',
      content: 'Microfinance Software facilitates efficient management microfinance institutions, empowering financial inclusion and streamlined operations.',
      date: '2024-02-25',
      image: 'MicroFin.png',
      link: '',
    },
   
    {
      id: 3,
      title: 'Gold Loan Software',
      content: 'Gold Loan Software streamlines gold loan management by automating appraisals, disbursements, interest calculations, repayments, and compliance, ensuring efficient operations for financial institutions.',
      date: '2024-02-25',
      image: 'GoldLoan.png',
      link: '/Gold-Loan-Software',
    },

    {
      id: 4,
      title: 'Group Loan Software',
      content: 'Group Loan Software simplifies managing group lending by automating loan disbursements, repayments, member tracking, and financial reporting for microfinance institutions.',
      date: '2024-02-25',
      image: 'GroupLoan.png',
      link: '/Group-Loan-Software',
    },

    {
        id: 5,
        title: 'Co-Operative Society Software',
        content: 'Co-Operative Society Software manages member records, deposits, loans, dividends, and compliance, streamlining operations and enhancing transparency for cooperative societies.',
        date: '2024-02-25',
        image: 'Cop.png',
        link: '/Co-Operative-Society-Software',
      },

      {
        id: 6,
        title: 'Co-Operative Bank Software',
        content: 'Co-Operative Bank Software streamlines banking operations, including account management, loans, deposits, compliance, and reporting, ensuring efficient and secure financial services for cooperative banks.',
        date: '2024-02-25',
        image: 'Cops.png',
        link: '/Co-Operative-Bank-Software',
      },

      {
        id: 7,
        title: 'Data Management',
        content: 'Data Management involves collecting, organizing, storing, and maintaining data securely, ensuring accuracy, accessibility, and efficiency for informed decision-making.',
        date: '2024-02-25',
        image: 'DataMan.png',
        link: '/Money-Transfer-Software',
      },
      
      {
        id: 8,
        title: 'API Integration Services',
        content: 'API Integration Services enable seamless connectivity between applications, allowing data exchange, functionality enhancement, and streamlined workflows for businesses.',
        date: '2024-02-25',
        image: 'Apiint.png',
        link: '/Recharge-&-Bill-Payment-Software',
      },

      {
        id: 9,
        title: 'Institute Management Software',
        content: 'Institute Management Software streamlines operations, automates processes,fees, and academics for educational institutions efficiently.',
        date: '2024-02-25',
        image: 'InstituteMan.png',
        link: '/',
      },

      {
        id: 10,
        title: 'Path Lab Software',
        content: 'A pathology lab analyzes body samples like blood, tissues, and fluids to diagnose diseases. medical treatments and health monitoring.',
        date: '2024-02-25',
        image: 'PathoLab.png',
        link: '/Path-Lab-Software',
      }
    
  ];

 


  return (
    <div className="cardrs0-container">
      <h2>Services</h2>
      <div className="cardrs-wrapper">
        {cardrsItems.map((cardrs) => (
          <div className="serv-card" key={cardrs.id}>
            <Link to={cardrs.link} className="card-link">
              <img src={cardrs.image} alt={cardrs.title} />
              <div className="cardrs-content">
                <h5>{cardrs.title}</h5>
                <p className="cardrscontent">{cardrs.content}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cardrs;






// import React, { useEffect } from 'react';
// import '../Css/Cardrs.css';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { Link } from 'react-router-dom';

// gsap.registerPlugin(ScrollTrigger);

// const Cardrs = () => {
//   const cardrsItems = [
//     {
//       id: 1,
//       title: 'Nidhi Company Software',
//       content: 'Hustle and Cashflow is a blog that aims to educate millennials on personal',
//       date: '2024-02-24',
//       image: 'news1.webp',
//       link: '/Nidhi-Company-Software',
//     },

//     {
//       id: 2,
//       title: 'Micro Finance Software',
//       content: 'I think that you should be able to select more than one reason for rating.',
//       date: '2024-02-25',
//       image: 'news2.webp',
//       link: '/Micro-Finance-Software',
//     },

//     {
//       id: 3,
//       title: 'Gold Loan Software',
//       content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//       date: '2024-02-25',
//       image: 'news3.webp',
//       link: '/Gold-Loan-Software',
//     },

//     {
//       id: 4,
//       title: 'Group Loan Software',
//       content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//       date: '2024-02-25',
//       image: 'news3.webp',
//       link: '/Group-Loan-Software',
//     },

//     {
//         id: 5,
//         title: 'Co-Operative Society Software',
//         content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//         date: '2024-02-25',
//         image: 'news3.webp',
//         link: '/Co-Operative-Society-Software',
//       },

//       {
//         id: 6,
//         title: 'Co-Operative Bank Software',
//         content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//         date: '2024-02-25',
//         image: 'news3.webp',
//         link: '/Co-Operative-Bank-Software',
//       },

//       {
//         id: 7,
//         title: 'Money Transfer Software',
//         content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//         date: '2024-02-25',
//         image: 'news3.webp',
//         link: '/Money-Transfer-Software',
//       },
      
//       {
//         id: 8,
//         title: 'Recharge & Bill Payment Software',
//         content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//         date: '2024-02-25',
//         image: 'news3.webp',
//         link: '/Recharge-&-Bill-Payment-Software',
//       },

//       {
//         id: 9,
//         title: 'AePS & Micro-ATM Software',
//         content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//         date: '2024-02-25',
//         image: 'news3.webp',
//         link: '/AePS-&-Micro-ATM-Software',
//       },

//       {
//         id: 10,
//         title: 'Path Lab Software',
//         content: 'Join us for upcoming events to learn more about the latest trends in technology.',
//         date: '2024-02-25',
//         image: 'news3.webp',
//         link: '/Path-Lab-Software',
//       }
    
//   ];

 


//   return (
//     <div className="cardrs0-container">
//       <h2>Services</h2>
//       <div className="cardrs-wrapper">
//         {cardrsItems.map((cardrs) => (
//           <Link to={cardrs.link} className="serv-card" key={cardrs.id}>
//             <img src={cardrs.image} alt={cardrs.title} />
//             <div className="cardrs-content">
//               <h3>{cardrs.title}</h3>
//               <p className="cardrscontent">{cardrs.content}</p>
//             </div>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Cardrs; 