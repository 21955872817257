import React from 'react';
import '../Css/Additional.css';

const Additional = () => {
  // Data for the cards
  const cardData = [
    { name: 'Website Designing & Development', img: 'acc.png'},
    { name: 'Android App Development', img: 'mob.png'},
    { name: 'IOS App Development', img: 'web.png'},
    { name: 'Logo & Graphics Designing', img: 'real.png'},
    { name: 'Web Portal & Development', img: 'MicroFin.png'},
    { name: 'Device Inegration', img: 'mob.png'},
    { name: 'Custom Web Development', img: 'cop.png'},
    { name: 'API Inegration' , img: 'Apiint.png'},
    
    



    // { name: 'EMMA JOHNSON', number: '3333 333 333 3333', validity: 'VALID', expiry: '04/31' },
    // { name: 'EMMA JOHNSON', number: '3333 333 333 3333', validity: 'VALID', expiry: '04/31' },
    // { name: 'EMMA JOHNSON', number: '3333 333 333 3333', validity: 'VALID', expiry: '04/31' },
    // { name: 'EMMA JOHNSON', number: '3333 333 333 3333', validity: 'VALID', expiry: '04/31' },
    

  ];

  return (
    <>
    <div class="outer_container_x my-3 row gap-3">
    <h1 className='text-center text-light'>Additional Development</h1>
      {cardData.map((cardadd, index) => (
         <div class="container_x">
             <div  key={index} class="box_2">
               <span class="text-md title">{cardadd.name}</span>
               <div>
                 <strong></strong>
                 <p>{cardadd.validity}</p>
                 <img src={cardadd.img} />
                 {/* <span>VALID</span> <span>{cardadd.expiry}</span> */}
               </div>
             </div>
           </div>
    ))}
    </div>
    
    {/* <div className="container-add">
    {cardData.map((cardadd, index) => (
     <div className="cards-add-container">
        {/* Mapping over card data to generate cards */}
          {/* <div key={index} className="box-add">
            <span className="title-add">GLASS EFFECT</span>
            <div>
              <strong>{cardadd.name}</strong>
              <p>{cardadd.number}</p>
              <span>{cardadd.validity}</span> <span>{cardadd.expiry}</span>
            </div>
          </div>
      </div> */}
    
    {/* ))} */}
    {/* </div> */} 
    
    </>
    
  );
};

export default Additional;

