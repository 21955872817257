import React from 'react'
import Feature from '../Components/Feature'
import Phone from '../Components/Phone'
import Cardrs from '../Components/Cardrs';


const Products = () => {
  return (
    <div className='product-container'>
     
     <h2 className='hehe'>Products</h2>
        
        <Cardrs/>
      <Feature/>
      <Phone/>
    </div>
  )
}

export default Products